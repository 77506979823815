import type { Report, SystemVersion } from '../types/api'
import axiosInstance from '@/plugins/axios'

export async function fetchReportList(businessProcessType: string): Promise<Report[]> {
  const response = await axiosInstance.get<Report[]>(
    `/reports/${businessProcessType}`,
  )

  return response.data
}

export async function fetchSystemVersion(): Promise<SystemVersion> {
  const response = await axiosInstance.get<SystemVersion>('/data/version')

  return response.data
}
