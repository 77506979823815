export function getPluralIndex(i: number) {
  if (i % 10 === 1 && i % 100 !== 11)
    return 0

  if (i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 10 || i % 100 >= 20))
    return 1

  return 2
}

/**
 * @param len {number} entity count
 * @param forms {String[]} words ex: ['enitity', 'enitities', 'enitities']
 * @example pluralize(2, ['enitity', 'enitities', 'enitities']) => 'enitities'
 *
 * @return Pluralized string
 */
export function pluralize(len: number, forms: string[]): string {
  const idx = getPluralIndex(len)

  return forms[idx]
}

export function toPluralizedText(len: number, forms: string[]) {
  const label = pluralize(len, forms)

  return `${len} ${label}`
}
