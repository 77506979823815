import { generateUniqueId } from '../utils/generateUniqueId'
import useBroadcastChannelNative from './useBroadcastChannelNative'

export interface IActiveTabWatcherSettings {
  // eslint-disable-next-line ts/no-unsafe-function-type
  onUpdateCallback?: Function
}

const UPDATE_CALLBACK_TIMEOUT = 500 // wait for server until changes applied
const workingAreaKey = generateUniqueId() // The key is required to identify the tab on which the changes occurred

export function useActiveTabWatcher(settings?: IActiveTabWatcherSettings) {
  const messageBroker = useBroadcastChannelNative('global')

  const applyActiveTabChanges = () => {
    messageBroker.send({
      id: 'change',
      payload: { key: workingAreaKey },
    })
  }

  messageBroker.on('change', async (payload) => {
    if (payload.key === workingAreaKey) // exclude active tab
      return

    if (settings?.onUpdateCallback)
      setTimeout(settings.onUpdateCallback, UPDATE_CALLBACK_TIMEOUT) // wait for server until changes applied
  })

  return {
    applyChanges: applyActiveTabChanges,
  }
}
