const abc = 'abcdefghijklmnopqrstuvwxyz'

export function generateUniqueId(length = 8): string {
  let output = ''

  while (output.length < length)
    output += generateLetter()

  return output
}

function generateLetter(): string {
  return abc[Math.floor(Math.random() * abc.length)]
}
