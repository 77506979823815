import { onUnmounted } from 'vue'

interface CrossTabPayload {
  id: string
  payload?: any
}

export default (name: string) => {
  const bc = new BroadcastChannel(name)

  const send = (data: CrossTabPayload) => bc.postMessage(data)

  const on = (id: string, cb: (_p: any) => void) => {
    bc.onmessage = (event: MessageEvent) => {
      if (event.data.id === id)
        cb(event.data.payload)
    }
  }

  onUnmounted(() => bc.close())

  return {
    send,
    on,
  }
}
