import type { Ref } from 'vue'
import { ref } from 'vue'
import isFunction from 'lodash/isFunction'
import type { DynamicConfirmModalSettings } from './types'

export const isVisibleDynamicModal = ref(false)
export const titleDynamicModal = ref<undefined | DynamicConfirmModalSettings['title']>()
export const descriptionDynamicModal = ref<undefined | DynamicConfirmModalSettings['description']>()
export const optionsDynamicModal = ref<DynamicConfirmModalSettings['options']>([])
const resolvePromiseDynamicModal = ref(undefined) as Ref<any>
export const rejectPromiseDynamicModal = ref(undefined) as Ref<any>
export function showDynamicConfirmModal(settings: DynamicConfirmModalSettings): Promise<any | Error> {
  titleDynamicModal.value = settings.title
  descriptionDynamicModal.value = settings.description ?? ''
  optionsDynamicModal.value = settings.options
  isVisibleDynamicModal.value = true

  return new Promise((resolve, reject) => {
    resolvePromiseDynamicModal.value = resolve
    rejectPromiseDynamicModal.value = reject
  })
}

export function confirmDynamicConfirmModal(value: string) {
  if (isFunction(resolvePromiseDynamicModal.value))
    resolvePromiseDynamicModal.value(value)

  isVisibleDynamicModal.value = false
}

export function rejectDynamicConfirmModal() {
  if (isFunction(rejectPromiseDynamicModal.value))
    rejectPromiseDynamicModal.value()

  isVisibleDynamicModal.value = false
}
